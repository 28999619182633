$theme-color-red: #880a1f;

.rt-tr.-odd {
  background: #f8f8f8;
  &:hover {
    background: lightgray;
  }
}
.card {
  border-radius: 15px;
  margin-top: 10px;
  .card-body{
    padding: 30px;
  }
}

.rt-table {
  border-top-color: gray;
  border-top-width: 0.1px;
  border-top-style: solid;
}

.-header{
  height: 50px;
}

.rt-tr-group {
  &:hover {
    background: lightgray;
  }
}

.table-heading-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.table-wrapper {
  margin: 10px 30px 30px;
}

.parking-dashboard {
  margin-left: 30px !important;
  margin-right: 30px;
}

.parking-dashboard-tables-wrapper {
  margin-left: 26px !important;
  margin-right: 30px;
  margin-bottom: 30px;
}

.dashboard-middle-card {
  margin-left: 20px;
  margin-right: 20px;
}

.parking-dashboard-first-table-title {
  color: gray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.parking-dashboard-info-card {
  width: 100%;
}

card-height-100 {
  height: 100%;
}

.little-cards-wrapper{
  padding-left: 5px;
}


.parking-dashboard-parking-status-title {
  margin-bottom: 10px;
}

.circle {
  display: block;
  width: 15px;
  height: 15px;
  border-color: blue;
  border-style: solid;
  border-radius: 20px;
}

.cards-wrapper {
  margin-right: 45px;
  margin-left: 45px;
}

.second-card {
  height: 290px;
}

.modal-body-video-player {
  height: 100%;
}

.camera-button-toggle {
  padding: 0 15px;
}

.refresh-icon {
  color: gray;
  cursor: pointer;
  position: relative;
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 5px;

  &:hover {
    color: $theme-color-red;
  }

}

.service-zone-row {
  width: 100%;
  display: flex;
  border-top-width: 0.5px;
  //&:hover {
  //  background: lightgray;
  //}
}

.service-zone-row-odd {
  background: #f8f8f8;
  width: 100%;
  display: flex;
  //&:hover {
  //  background: lightgray;
  //}
}

@media (max-width: 599px) {
  .service-zone-row, .service-zone-row-odd {
    flex-direction: column;
  }
}
