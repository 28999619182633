$page-footer-padding: 1rem;

$logoPath: "/assets/img/andi-logo.png";
$logoPathMobile: "/assets/img/andi-logo-small.png";
$logoTextPath: "/assets/img/logotype_text.png";


.full-height {
  height: 100%;
}

.leaflet-popup-content p {
  margin: 4px;
}

.icon-class {
  background-color: transparent;
}

.fileCloseButtonIcon {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  overflow: hidden;
  vertical-align: middle;
  height: 14px;
  width: 14px;
}

.fileCloseButton:hover {
  background-color: #feb1a2;
}

.fileCloseButton:hover .fileCloseButtonIconPath {
  fill: #e31414;
}

/* VERTICAL TIMELINE COMPONENT */
/* The outer timeline */
.vertical-timeline.vertical-timeline-custom-line {
  padding: 0;
}

.vertical-timeline.vertical-timeline-client {
  padding: 0;
  margin: 0;
  max-width: none;
  width: 100%;
}

/* The line */
.vertical-timeline.vertical-timeline-custom-line::before {
  background: #eee;
  left: 4px;
  width: 2px;
  margin-top: 20px;
  height: calc(100% - 20px);
}

.vertical-timeline.vertical-timeline-client::before {
  background: #eee;
  left: 4px;
  width: 2px;
  margin-top: 20px;
  height: calc(100% - 20px);
}

/* Icon container's border */
.vertical-timeline.vertical-timeline-custom-line .vertical-timeline.vertical-timeline-client .vertical-timeline-element--black .vertical-timeline-element-icon {
  box-shadow: 0 0 0 2px #eee;
}

.align-right {
  margin-left: auto;
}

.ap-img-size {
  height: 60px !important;
  width: 60px !important;
}

.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col,
.col-auto,.col-xs-1,.col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,
.col-xs-10,.col-xs-11,.col-xs-12,.col-xs,.col-xs-auto,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,
.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm,.col-sm-auto,.col-md-1,
.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,
.col-md-12,.col-md,.col-md-auto,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,
.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg,.col-lg-auto,.col-xl-1,.col-xl-2,.col-xl-3,
.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl,
.col-xl-auto,.col-xxl-1,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,
.col-xxl-9,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl,.col-xxl-auto{
  position:relative;
  width:100%;
  padding-right: 5px;
  padding-left: 5px;
}

.container {
  padding-left: 0;
  padding-right: 5px;
}

.flex-gap {
  > input {
    margin: 6px;
  }
}

.no-pad-dropdown {
  > div {
    padding: 0px;
  }
  div:nth-of-type(2) {
    padding: 0px 10px;
  }
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  z-index: 0;
}

.main-style {
  height: 100%;
  margin: 92px 12px 12px 12px;
  transition: none;
}
